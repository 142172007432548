import React from "react";
import { Provider } from "react-redux";
import Routers from "./routeConfig";
import { store } from "./redux/store";
import { HelmetProvider } from 'react-helmet-async';
import "@fontsource/poppins";
import '@fontsource/inter'
import '@fontsource/urbanist'; 
import '@fontsource/outfit'

function App() {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <Routers />
      </Provider>
    </HelmetProvider>
  );
}
export default App;
